import React from 'react'
import { notification, Alert } from 'antd'
import PropTypes from 'prop-types'

const httpCodeDescriptions = {
  200: 'Success',
  201: 'Success',
  400: 'Internal Error',
  401: 'Unauthorised',
  412: 'Precondition Failed',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Internal Error',
  500: 'Internal Error',
  503: 'Unavailable',
  1001: 'License Error',
  1002: 'License Warning',
  1003: 'Measurement Error'
}

export const openNotification = (title, desc) => {
  const typeObj = {
    200: 'success',
    201: 'success',
    400: 'warning',
    401: 'warning',
    403: 'error',
    412: 'error',
    404: 'error',
    405: 'error',
    500: 'error',
    503: 'error',
    1001: 'error',
    1002: 'warning',
    1003: 'error'

  }

  const type = typeObj[title]
  const stringTitle = httpCodeDescriptions[title]

  const args = {
    message: stringTitle,
    description: desc,
    duration: 10,
    placement: 'bottomRight'
  }
  notification[type](args)
}

const ErrorMessages = ({ error }) => {
  return (
    <Alert
      message={`Error: ${httpCodeDescriptions[error.status]}`}
      description={error.description !== undefined ? error.description : error.data.msg}
      type='error'
      showIcon
    />
  )
}
ErrorMessages.propTypes = {
  error: PropTypes.any
}
export default ErrorMessages
